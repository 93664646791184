import * as types from "./types";
import createReducer from "../../utils/createReducer";
import initialState from './initial-state';
import { encodeString } from 'common/utils/utils'

let reducersMap = {
   [types.FETCH_REQUEST_START]: (state, action) => {
      const isFetchingByCode = action.payload
      return {
         ...state,
         isFetching: isFetchingByCode ? false : true,
         isFetchingPricingsByCode: isFetchingByCode,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { data, avilableMemberships } } = action;
      return {
         ...state,
         isFetching: false,
         membershipsPricings: data,
         contentAvilableMembership: avilableMemberships,
         isFetchingPricingsByCode: false,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         isFetchingPricingsByCode: false,
      }
   },

   [types.FETCH_PAYMENT_OPTIONS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
         candidatePaymentOptions: {},
      }
   },
   [types.FETCH_PAYMENT_OPTIONS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetching: false,
         candidatePaymentOptions: payload,

      }
   },
   [types.FETCH_PAYMENT_OPTIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.PAYMENT_PURCHASE_START]: (state, action) => {
      return {
         ...state,
         isFetchingJoin: action.payload,
      }
   },
   [types.PAYMENT_PURCHASE_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingJoin: false,
         payment: action.payload,
      }
   },
   [types.PAYMENT_PURCHASE_UPDATE_DATA]: (state, action) => {
      return {
         ...state,
         payment: action.payload,
      }
   },
   [types.PAYMENT_PURCHASE_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingJoin: false,
         paymentError: action.payload,
      }
   },

   [types.CHOOSE_PURCHASE_COMPLETED]: (state, action) => {
      return {
         ...state,
         activePurchase: action.payload,
      }
   },
   [types.PAYMENT_DECLINED_START]: (state, action) => {
      return {
         ...state,
         isFetchingDeclined: true,
      }
   },

   [types.PAYMENT_DECLINED_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingDeclined: false,
         paymentDeclinedInfo: action.payload,
      }
   },

   [types.CLEAR_ERROR_ACTION]: (state, action) => {
      return {
         ...state,
         paymentError: action.payload,
      }
   },
   [types.PAYMENT_PURCHASE_USING_WALLET]: (state, action) => {
      return {
         ...state,
         fetchPaymentUsingWallet: action.payload,
      }
   },
   [types.SET_CONTENT_AVILABLE_MEMBERSHIP]: (state, action) => {
      return {
         ...state,
         contentAvilableMembership: action.payload,
      }
   },

   [types.VALIDATE_PROMO_CODE_START]: (state) => {
      return {
         ...state,
         isFetchingValidatePromoCode: true,
      }
   },
   [types.VALIDATE_PROMO_CODE_COMPLETED]: (state, action) => {
      const { code, isCodeByLink } = action.payload
      return {
         ...state,
         isFetchingValidatePromoCode: false,
         isCodeApplied: true,
         promoCode: code,
         isAppliedByLink: isCodeByLink,
         encodedPromoCode: isCodeByLink ? encodeString(code) : null,
      }
   },
   [types.VALIDATE_PROMO_CODE_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingValidatePromoCode: false,
         promoCodeError: action.payload,
      }
   },
   [types.UPDATE_PROMO_CODE_ERROR]: (state, action) => {
      return {
         ...state,
         promoCodeError: action.payload,
      }
   },
   [types.CLEAR_APPLIED_STATE]: (state) => {
      return {
         ...state,
         isCodeApplied: false,
         promoCode: null,
      }
   },
   [types.SET_FREE_TRAIL_MODAL]: (state, action) => {
      return {
         ...state,
         freeTrailModal: action.payload,
      }
   },
};

export default createReducer(initialState)(reducersMap);
